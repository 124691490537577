import { Navigate, Route, Routes } from 'react-router-dom';
import Home from './Home';

export default function App() {
  return (
    <Routes>
      <Route path="/en" element={<Home lang="en" />} />
      <Route path="/zh" element={<Home lang="zh" />} />
      <Route path="*" element={<Navigate to="/en" />} />
    </Routes>
  );
}
