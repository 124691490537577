import './home.css';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import Button from './components/Button';

const nls = {
  contactUS: {
    en: 'Contact us',
    zh: '联系我们',
  },
  lang: {
    en: '中 文',
    zh: 'English',
  },
  email: {
    en: 'E-Mail',
    zh: '需求邮箱',
  },
  phone: {
    en: 'Mobile',
    zh: '联系电话',
  },
  address: {
    en: 'Address',
    zh: '地址',
  },
  location: {
    en: 'Bld. D No.155 XinSheng Rd. Qingpu district Shanghai',
    zh: '上海市青浦区新胜路155号D幢',
  },
  website: {
    en: 'Website',
    zh: '网址',
  },
  company: {
    en: 'Shanghai DC Tech. LTD.',
    zh: '上海笃粹科技有限公司',
  },
};

export default function Home({ lang = 'en' }) {
  const [contact, setContact] = useState(false);
  return (
    <article>
      <section className={`contacts ${contact ? 'contacts-push' : ''}`}>
        <p>
          <span />
          {nls.company[lang]}
        </p>
        <p>
          <span>
            {nls.email[lang]}
            :
          </span>
          <a href="mailto:RFQ.service@shducui.com">
            RFQ.service@shducui.com
          </a>
        </p>
        <p>
          <span>
            {nls.phone[lang]}
            :
          </span>
          +86 189-1886-7969
        </p>
        <p>
          <span>
            {nls.address[lang]}
            :
          </span>
          {nls.location[lang]}
        </p>
        <p>
          <span>
            {nls.website[lang]}
            :
          </span>
          www.shducui.com
        </p>
      </section>
      <section className="home-link-buttons">
        <Button onClick={() => setContact(!contact)}>
          {nls.contactUS[lang]}
        </Button>
        <Link to={`/${lang === 'en' ? 'zh' : 'en'}`}>
          <Button>{nls.lang[lang]}</Button>
        </Link>
      </section>
      <section className={`home-bg home-bg-${lang} ${contact ? 'home-bg-push' : ''}`} />
    </article>
  );
}
