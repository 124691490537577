import './button.css';

export default function Button({ children, ...props }) {
  return (
    <button
      className="dc-button"
      type="button"
      {...props}
    >
      {children}
    </button>
  );
}
